import { differenceInDays, format, parse } from 'date-fns';
import React from 'react';

export const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return phoneNumberString;
};

/**
 *
 * @param {*} dateString DD/MM/YYYY
 * @returns ISO 8601 string
 */
export const dateToISOString = (dateString) => {
    const [mm, dd, yyyy] = dateString.split('/');
    return new Date(Date.UTC(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd))).toISOString();
};

export const ignoreTimeZone = (dateISOString) => {
    const isoDate = new Date(dateISOString);
    const localOffsetFromUTCInMilliseconds = isoDate.getTimezoneOffset() * 60 * 1000;

    return new Date(
        isoDate.getTime() +
            localOffsetFromUTCInMilliseconds * Math.sign(localOffsetFromUTCInMilliseconds),
    );
};

// ignore timezone and return date string from iso8601 string
export const formatDate = (dateISOString, forInput = false) => {
    if (!dateISOString) return 'Unknown';
    const d = ignoreTimeZone(dateISOString);
    //Format to yyyy-mm-dd for inputs
    if (forInput) {
        return `${d.getFullYear()}-${(1 + d.getMonth()).toString().padStart(2, '0')}-${d
            .getDate()
            .toString()
            .padStart(2, '0')}`;
    } else return d.toLocaleDateString();
};

//Ignore timezone and return date in format MM/dd/yyyy
export const formatBirthdate = (dateISOString) => {
    if (!dateISOString) return 'Unknown';
    const d = ignoreTimeZone(dateISOString);
    return `${(1 + d.getMonth()).toString().padStart(2, '0')}/${d
        .getDate()
        .toString()
        .padStart(2, '0')}/${d.getFullYear()}`;
};

export const formatSquareDate = (dateISOString) => {
    return new Date(dateISOString).toLocaleDateString();
};

export function determineSearchTermValue(searchTermValue, searchTermType) {
    switch (searchTermType?.value) {
        case 'nameSearch':
            if (
                searchTermValue.basicSearchTerm.length > 0 &&
                searchTermValue.lastNameSearchTerm.length > 0
            ) {
                return {
                    AND: [
                        {
                            firstName: {
                                contains: searchTermValue.basicSearchTerm,
                                mode: 'insensitive',
                            },
                        },
                        {
                            lastName: {
                                contains: searchTermValue.lastNameSearchTerm,
                                mode: 'insensitive',
                            },
                        },
                    ],
                };
            } else if (searchTermValue.basicSearchTerm.length > 0) {
                return {
                    firstName: {
                        contains: searchTermValue.basicSearchTerm,
                        mode: 'insensitive',
                    },
                };
            } else {
                return {
                    lastName: {
                        contains: searchTermValue.lastNameSearchTerm,
                        mode: 'insensitive',
                    },
                };
            }

        case 'emailSearch':
            return {
                email: searchTermValue.basicSearchTerm.replace(/ /g, ''),
            };
        case 'patientIdSearch':
            return { id: +searchTermValue.basicSearchTerm };
        default:
            return null;
    }
}

export const postPaginatedQuery = ({
    appointments,
    careTeam,
    insurance,
    orderBy,
    patientProfileSearch,
    querySearch,
    resupply,
    searchTermType,
    searchTermValue,
    skip,
    state,
    take,
}) => {
    if (querySearch) {
        return {
            rawQueryId: querySearch.id,
            raw: querySearch.raw,
            skip: skip,
            take: take,
            where: querySearch.raw ? {} : querySearch.value.where,
            orderBy: querySearch.raw ? {} : querySearch.value.orderBy,
        };
    } else {
        let whereObject = { userRoles: { some: { role: 'PATIENT' } } }; // fix

        if (!!patientProfileSearch) {
            whereObject = { ...whereObject, patientProfile: patientProfileSearch };
        }

        if (careTeam) {
            let careTeamWhereObject = {
                careTeam: { some: { careTeamUserId: careTeam } },
            };
            whereObject = { ...whereObject, ...careTeamWhereObject };
        }

        if (insurance) {
            let insuranceWhereObject = {
                insurance: { some: { payerId: insurance } },
            };
            whereObject = { ...whereObject, ...insuranceWhereObject };
        }

        if (state) {
            let stateSearchWhereObject = {
                state,
            };
            whereObject = { ...whereObject, ...stateSearchWhereObject };
        }

        if (searchTermValue) {
            const searchTermWhereObject = determineSearchTermValue(searchTermValue, searchTermType);

            whereObject = { ...whereObject, ...searchTermWhereObject };
        }

        if (appointments) {
            let appointmentSearchWhereObject = {
                appointments: { some: { appointmentStatus: appointments } },
            };
            whereObject = { ...whereObject, ...appointmentSearchWhereObject };
        }

        if (resupply) {
            let resupplySearchWhereObject = {
                resupply: { some: { enrollmentStatus: resupply } },
            };
            whereObject = { ...whereObject, ...resupplySearchWhereObject };
        }

        return {
            skip: skip,
            take: take,
            where: whereObject,
            orderBy: orderBy,
        };
    }
};

export const formatOralQuestionnaireResponse = (key, value) => {
    let formattedValue = value;
    let formattedKey = key;

    switch (key) {
        case 'currentlyUsingCpap':
            formattedKey = 'Currently Using CPAP';
            break;

        case 'whyNotUsingCpap':
            formattedKey = 'Why Not Using CPAP';
            break;

        case 'whyNotUsingCpapOther':
            formattedKey = 'Other_Why Not Using';
            break;

        case 'whyLooking':
            formattedKey = 'Why Looking for Alternative';
            break;

        case 'whyLookingOther':
            formattedKey = 'Other_Why Alternative';
            break;

        case 'gained20Lbs':
            formattedKey = 'Changed More Than 20lbs';
            break;

        case 'hasHeartLungDisease':
            formattedKey = 'Has new Heart/Lung Disease';
            break;

        case 'anythingElse':
            formattedKey = 'Anything Else to Know';
            break;

        case 'createdAt':
            formattedKey = 'Created';
            break;

        case 'updatedAt':
            formattedKey = 'Updated';
            break;

        case 'height':
            formattedKey = 'Height';
            break;

        case 'weight':
            formattedKey = 'Weight';
            break;

        default:
            formattedKey = key;
            break;
    }

    if (value === null) formattedValue = 'N/A';
    if (typeof value === 'boolean') formattedValue = value ? 'Yes' : 'No';
    if (key === 'createdAt' || key === 'updatedAt') formattedValue = formatDate(value);

    return (
        <>
            <span className="text-gray-light">{formattedKey}: </span>
            {formattedValue}
        </>
    );
};

const formatItem = (key, value) => {
    let formattedValue = value;
    let formattedKey = key;

    switch (key) {
        case 'isOver50':
            formattedKey = 'Over 50yrs old';
            break;

        case 'isMale':
            formattedKey = 'Is Male';
            break;

        case 'isTired':
            formattedKey = 'Is Tired';
            break;

        case 'doesSnoreLoudly':
            formattedKey = 'Snores loudly';
            break;

        case 'doesStopBreathing':
            formattedKey = 'Stopped breathing';
            break;

        case 'hasPriorSleepStudy':
            formattedKey = 'Has a prior sleep study';
            break;

        case 'heightInches':
            formattedKey = 'Height';
            break;

        case 'weightLbs':
            formattedKey = 'Weight';
            break;

        case 'hasHypertension':
            formattedKey = 'Is Hypertensive';
            break;

        case 'anythingElse':
            formattedKey = 'Anything Else to Know';
            break;

        case 'createdAt':
            formattedKey = 'Created';
            break;

        case 'updatedAt':
            formattedKey = 'Updated';
            break;

        case 'previouslyDiagnosedWithSleepApnea':
            formattedKey = 'Previously diagnosed with OSA';
            break;

        case 'howBroughtToGem':
            formattedKey = 'Why at GEM';
            break;

        case 'hasPriorStudy':
            formattedKey = 'Has prior study';
            break;

        case 'gained20Lbs':
            formattedKey = 'Changed 20+ lbs';
            break;

        default:
            formattedKey = key;
            break;
    }

    if (value === null) formattedValue = 'N/A';
    if (typeof value === 'boolean') formattedValue = value ? 'Yes' : 'No';
    if (key === 'createdAt' || key === 'updatedAt') formattedValue = formatDate(value);

    if (key === 'weightLbs') formattedValue = value + 'lbs';

    if (key === 'heightInches') {
        const ft = value ? Math.floor(value / 12) : '';
        const inc = value ? value % 12 : '';

        formattedValue = `${ft}ft ${inc}in`;
    }

    return (
        <li className="py-1">
            <span className="text-gray-light">{formattedKey}: </span>
            {formattedValue}
        </li>
    );
};

export const formatStopBangResponses = (stopBangResponses, birthdate) => {
    return (
        <>
            {formatItem('doesSnoreLoudly', stopBangResponses.doesSnoreLoudly)}
            {formatItem('isTired', stopBangResponses.isTired)}
            {formatItem('doesStopBreathing', stopBangResponses.doesStopBreathing)}
            {formatItem('hasHypertension', stopBangResponses.hasHypertension)}
            {formatItem('heightInches', stopBangResponses.heightInches)}
            {formatItem('weightLbs', stopBangResponses.weightLbs)}

            <li className="py-1">
                <span className="text-gray-light">BMI: </span>
                {getBMI(stopBangResponses.weightLbs, stopBangResponses.heightInches)}
            </li>
            <li className="py-1">
                <span className="text-gray-light">Age: </span>
                {getAgeFromDateString(birthdate)}
            </li>
            {formatItem('isMale', stopBangResponses.isMale)}
            {formatItem('anythingElse', stopBangResponses.anythingElse)}
            {formatItem('createdAt', stopBangResponses.createdAt)}
        </>
    );
};

export const formatAltTreatmentResponses = (stopBangResponses) => {
    return (
        <>
            {formatItem(
                'previouslyDiagnosedWithSleepApnea',
                stopBangResponses.previouslyDiagnosedWithSleepApnea,
            )}
            {formatItem('howBroughtToGem', stopBangResponses.howBroughtToGem)}
            {formatItem('hasPriorStudy', stopBangResponses.hasPriorStudy)}
            {formatItem('gained20Lbs', stopBangResponses.gained20Lbs)}
            {formatItem('createdAt', stopBangResponses.createdAt)}
        </>
    );
};

export const isValidUploadFile = (file) =>
    ['image/jpeg', 'image/pjpeg', 'image/png', 'application/pdf'].includes(file.type);

export const returnFileSize = (number) => {
    if (number < 1024) {
        return number + 'bytes';
    } else if (number >= 1024 && number < 1048576) {
        return (number / 1024).toFixed(1) + 'KB';
    } else if (number >= 1048576) {
        return (number / 1048576).toFixed(1) + 'MB';
    }
};

export const cleanFilename = (filename) => {
    //replace control, non-printable, and all characters outside of standard ascii with an underscore in the file name
    return filename.replace(/[^\u0020-\u007E]/g, '_');
};

export const hearAboutUsOptions = [
    {
        label: 'Google or other search engine',
        value: 'SEARCH_ENGINE',
    },
    {
        label: 'Social media',
        value: 'SOCIAL_MEDIA',
    },
    {
        label: 'Podcast/Radio',
        value: 'RADIO',
    },
    {
        label: 'Family member or friend referral',
        value: 'FAMILY_OR_FRIEND',
    },
    {
        label: 'Physician/Dentist',
        value: 'PHYSICIAN_OR_DENTIST',
    },
    {
        label: 'Insurance carrier website/representative',
        value: 'INSURANCE',
    },
    {
        label: 'Employer',
        value: 'EMPLOYER',
    },
    {
        label: 'Blog or publication',
        value: 'BLOG',
    },
    {
        label: 'Other',
        value: 'OTHER',
    },
];

export const states = [
    {
        label: 'Minnesota',
        value: 'MN',
    },
    {
        label: 'Wisconsin',
        value: 'WI',
    },
    {
        label: 'Alabama',
        value: 'AL',
    },
    {
        label: 'Alaska',
        value: 'AK',
    },
    {
        label: 'Arizona',
        value: 'AZ',
    },
    {
        label: 'Arkansas',
        value: 'AR',
    },
    {
        label: 'California',
        value: 'CA',
    },
    {
        label: 'Colorado',
        value: 'CO',
    },
    {
        label: 'Connecticut',
        value: 'CT',
    },
    {
        label: 'Delaware',
        value: 'DE',
    },
    {
        label: 'District of Columbia',
        value: 'DC',
    },
    {
        label: 'Florida',
        value: 'FL',
    },
    {
        label: 'Georgia',
        value: 'GA',
    },
    {
        label: 'Hawaii',
        value: 'HI',
    },
    {
        label: 'Idaho',
        value: 'ID',
    },
    {
        label: 'Illinois',
        value: 'IL',
    },
    {
        label: 'Indiana',
        value: 'IN',
    },
    {
        label: 'Iowa',
        value: 'IA',
    },
    {
        label: 'Kansas',
        value: 'KS',
    },
    {
        label: 'Kentucky',
        value: 'KY',
    },
    {
        label: 'Louisiana',
        value: 'LA',
    },
    {
        label: 'Maine',
        value: 'ME',
    },
    {
        label: 'Maryland',
        value: 'MD',
    },
    {
        label: 'Massachusetts',
        value: 'MA',
    },
    {
        label: 'Michigan',
        value: 'MI',
    },
    {
        label: 'Mississippi',
        value: 'MS',
    },
    {
        label: 'Missouri',
        value: 'MO',
    },
    {
        label: 'Montana',
        value: 'MT',
    },
    {
        label: 'Nebraska',
        value: 'NE',
    },
    {
        label: 'Nevada',
        value: 'NV',
    },
    {
        label: 'New Hampshire',
        value: 'NH',
    },
    {
        label: 'New Jersey',
        value: 'NJ',
    },
    {
        label: 'New Mexico',
        value: 'NM',
    },
    {
        label: 'New York',
        value: 'NY',
    },
    {
        label: 'North Carolina',
        value: 'NC',
    },
    {
        label: 'North Dakota',
        value: 'ND',
    },
    {
        label: 'Ohio',
        value: 'OH',
    },
    {
        label: 'Oklahoma',
        value: 'OK',
    },
    {
        label: 'Oregon',
        value: 'OR',
    },
    {
        label: 'Pennsylvania',
        value: 'PA',
    },
    {
        label: 'Rhode Island',
        value: 'RI',
    },
    {
        label: 'South Carolina',
        value: 'SC',
    },
    {
        label: 'South Dakota',
        value: 'SD',
    },
    {
        label: 'Tennessee',
        value: 'TN',
    },
    {
        label: 'Texas',
        value: 'TX',
    },
    {
        label: 'Utah',
        value: 'UT',
    },
    {
        label: 'Vermont',
        value: 'VT',
    },
    {
        label: 'Virginia',
        value: 'VA',
    },
    {
        label: 'Washington',
        value: 'WA',
    },
    {
        label: 'West Virginia',
        value: 'WV',
    },
    {
        label: 'Wyoming',
        value: 'WY',
    },
].sort((a, b) => (a.label > b.label ? 1 : -1));

//The following Status Options are used in the patientStatusesWithOptions below
// Would it be better to use Created Verified and Created Not Verified? Or even Verified and Not Verified?
// Seems a little pointless to ahve the value and label repeat versus just having an enum of the values
export const accountCreationStatuses = ['CREATED_VERIFIED', 'CREATED_NOT_VERIFIED'];

export const dentalReferralStatusOptions = [
    { value: 'NOT_INITIATED', label: 'NOT_INITIATED' },
    { value: 'REQUIRES_TELEHEALTH_VISIT', label: 'REQUIRES_TELEHEALTH_VISIT (disabled)' },
    { value: 'INITIAL_CONSULT_SCHEDULED', label: 'INITIAL_CONSULT_SCHEDULED' },
    { value: 'INITIAL_CONSULT_COMPLETE', label: 'INITIAL_CONSULT_COMPLETE' },
    {
        value: 'INITIAL_DENTAL_VISIT_COMPLETE',
        label: 'INITIAL_DENTAL_VISIT_COMPLETE',
    },
    { value: 'IN_TREATMENT', label: 'IN_TREATMENT' },
    { value: 'ON_HOLD', label: 'ON_HOLD' },
    { value: 'PATIENT_REQUESTS_CALL_BACK', label: 'PATIENT_REQUESTS_CALL_BACK' },
];

export const homeSleepStudyStatuses = [
    'NOT_INITIATED',
    'STUDY_IN_CART',
    'STUDY_ORDERED',
    'HST_FULFILLMENT_ORDERED',
    'HST_FULFILLMENT_SHIPPED',
    'HST_FULFILLMENT_DELIVERED',
    'SERIAL_NUMBER_ADDED',
    'STUDY_COMPLETED',
    'STUDY_REVIEWED',
    'REORDER',
    'HST_RECEIVED_IN_OFFICE',
];

export const cpapOrderStatuses = [
    'NOT_INITIATED',
    'NEEDS_REFERRAL',
    'REFERRED',
    'REFERRAL_CANCELLED',
    'SUPPLIES_IN_CART',
    'SUPPLIES_ORDERED',
    'FULFILLMENT_ORDER',
    'SHIPPED',
    'DELIVERED',
    'SOVA_SAGE_COMPLETE',
];

export const patientInDMEReferral = ['NEEDS_REFERRAL', 'REFERRED'];

export const supplyOrdersStatuses = [
    'NOT_INITIATED',
    'ORDERED',
    'FULFILLMENT_ORDER_PLACED',
    'SHIPPED',
    'DELIVERED',
];

export const treatmentSupportStatuses = [
    'NOT_INITIATED',
    'TREATMENT_AVAILABLE',
    'TREATMENT_SELECTED',
    'SETUP_COMPLETED',
    'DELIVERY_EMAIL_SENT',
    'WORE_7_DAYS',
    'WORE_15_DAYS',
    'WORE_30_DAYS',
    'REQUESTED_MASK_REPLACEMENT',
    'REQUIRES_RETEST',
    'TREATMENT_SUPPORT_ONLY',
];

export const mdStatuses = [
    'NOT_INITIATED',
    'PENDING_CLINICAL_REVIEW',
    'CLINICIAN_TICKET_CREATED',
    'HST_LAB_ORDER_COMPLETE',
    'ITAMAR_HST_NEEDS_REVIEW',
    'ITAMAR_HST_REVIEW_COMPLETE',
    'HST_COMPLETE',
    'DOES_NOT_QUALIFY_FOR_HST',
    'UPLOADED_SLEEP_STUDY_PENDING_CLINICAL_REVIEW',
    'UPLOADED_SLEEP_STUDY_INCOMPLETE',
    'UPLOADED_SLEEP_STUDY_APPROVED',
];

export const appointmentStatusOptions = [
    {
        value: 'SCHEDULED',
        label: 'Scheduled',
    },
    {
        value: 'NOSHOW',
        label: 'No Show',
    },
    {
        value: 'CANCELLED',
        label: 'Canceled',
    },
    {
        value: 'OCCURRED',
        label: 'Occurred',
    },
];

export const appointmentTypeOptions = [
    'Compliance Visit',
    'Diagnosis Visit',
    'Initial Consultation',
    'Required Consult',
];

export const lastComplianceOptions = [
    { value: 'none', label: 'None' },
    {
        value: 'lte',
        label: 'More Than One Year Ago',
    },
    {
        value: 'gte',
        label: 'Less Than One Year Ago',
    },
];

export const enrollmentStatuses = ['ENROLLED', 'CANCELLED'];

//Used in the admin status filters
export const patientStatusesWithOptions = [
    {
        available: true,
        value: 'accountCreationStatus',
        label: 'Account Creation Status',
        statusOptions: accountCreationStatuses.map((option) => ({ value: option, label: option })),
    },
    {
        available: true,
        value: 'dentalReferralStatus',
        label: 'Dental Referral Status',
        statusOptions: dentalReferralStatusOptions,
    },
    {
        available: true,
        value: 'homeSleepStudyStatus',
        label: 'Home Sleep Study Status',
        statusOptions: homeSleepStudyStatuses.map((option) => ({ value: option, label: option })),
    },
    {
        available: true,
        value: 'cpapOrderStatus',
        label: 'CPAP Order Status',
        statusOptions: cpapOrderStatuses.map((option) => ({ value: option, label: option })),
    },
    {
        available: true,
        value: 'mdStatus',
        label: 'Clinical Status',
        statusOptions: mdStatuses.map((option) => ({ value: option, label: option })),
    },
    {
        available: true,
        value: 'supplyOrdersStatus',
        label: 'Supply Order Status',
        statusOptions: supplyOrdersStatuses.map((option) => ({ value: option, label: option })),
    },
    {
        available: true,
        value: 'referToSleepClinic',
        label: 'Referred to Sleep Clinic',
        statusOptions: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
        ],
    },
    {
        available: true,
        value: 'referredForCpap',
        label: 'Referred for CPAP',
        statusOptions: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
        ],
    },
    {
        available: true,
        value: 'isDentalReferred',
        label: 'Referred for Dental',
        statusOptions: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
        ],
    },
    {
        available: true,
        value: 'needsSleepStudyRetest',
        label: 'Needs Retest',
        statusOptions: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
        ],
    },
    {
        available: true,
        value: 'appointments',
        label: 'Appointment',
        statusOptions: appointmentStatusOptions,
    },
    {
        available: true,
        value: 'state',
        label: 'State',
        statusOptions: states,
    },
    {
        available: true,
        value: 'resupply',
        label: 'Resupply Enrollment',
        statusOptions: enrollmentStatuses.map((option) => ({ value: option, label: option })),
    },
];

export const otherFilters = ['careTeam', 'insurance', 'appointments', 'state', 'resupply'];

//Used for name/email/patientId search in admin panel
export const adminFilterOptions = [
    { value: 'nameSearch', label: 'Name', text: 'Partial Name' },
    { value: 'emailSearch', label: 'Email', text: 'Full Email' },
    { value: 'patientIdSearch', label: 'Patient ID', text: 'Patient ID' },
];

//Estimate statuses for orders
export const estimateStatuses = [
    {
        label: 'Not Required',
        value: null,
    },
    {
        label: 'Needed',
        value: 'ESTIMATE_NEEDED',
    },
    { label: 'Pending', value: 'ESTIMATE_PENDING' },
    {
        label: 'Complete',
        value: 'ESTIMATE_COMPLETE',
    },
    {
        label: 'With Patient',
        value: 'ESTIMATE_WITH_PATIENT',
    },
    {
        label: 'Patient Approved',
        value: 'ESTIMATE_PATIENT_APPROVED',
    },
    {
        label: 'Rejected',
        value: 'ESTIMATE_REJECTED',
    },
    {
        label: 'Out of Network',
        value: 'OUT_OF_NETWORK',
    },
    {
        label: 'Not Covered Services',
        value: 'NOT_COVERED_SERVICES',
    },
];

//Statuses for prior auth in orders
export const priorAuthStatuses = [
    {
        label: 'Not Indicated',
        value: null,
    },
    {
        label: 'Not Required',
        value: 'PRIOR_AUTH_NOT_REQUIRED',
    },
    {
        label: 'After Visit/Approval',
        value: 'PRIOR_AUTH_AFTER_CONSULT',
    },
    {
        label: 'Needed',
        value: 'PRIOR_AUTH_NEEDED',
    },
    {
        label: 'Pending',
        value: 'PRIOR_AUTH_PENDING',
    },
    {
        label: 'Approved',
        value: 'PRIOR_AUTH_APPROVED',
    },
    {
        label: 'Denied',
        value: 'PRIOR_AUTH_DENIED',
    },
];

export const orderTypes = [
    {
        label: 'Cash',
        value: 'CASH',
    },
    {
        label: 'Insurance',
        value: 'INSURANCE',
    },
    {
        label: 'Replacement',
        value: 'REPLACEMENT',
    },
];

export const orderStatuses = [
    {
        label: 'Completed',
        value: 'COMPLETED',
    },
    {
        label: 'Canceled',
        value: 'CANCELLED',
    },
    {
        label: 'Pending',
        value: 'PENDING',
    },
];

export const initialComplianceOptions = ['COMPLIANT', 'NOT_COMPLIANT', 'AT_RISK'];

//Used for filtering on the Orders tab in admin panel
export const orderStatusesWithOptions = [
    {
        available: true,
        value: 'priorAuthStatus',
        label: 'Prior Auth Status',
        statusOptions: priorAuthStatuses,
        multiple: true,
    },
    {
        available: true,
        value: 'estimateStatus',
        label: 'Estimate Status',
        statusOptions: estimateStatuses,
        multiple: true,
    },
    {
        available: true,
        value: 'orderStatus',
        label: 'Order Status',
        statusOptions: orderStatuses,
        multiple: true,
    },
    {
        available: true,
        value: 'orderType',
        label: 'Order Type',
        statusOptions: orderTypes,
        multiple: true,
    },
    {
        available: true,
        value: 'insuranceStatus',
        label: 'Insurance Status',
        statusOptions: [
            {
                label: 'Review',
                value: 'Review',
            },
            {
                label: 'Active',
                value: 'Active',
            },
            {
                label: 'Inactive',
                value: 'Inactive',
            },
        ],
        multiple: true,
    },
    {
        available: true,
        value: 'homeSleepStudyStatus',
        label: 'Sleep Study Status',
        statusOptions: homeSleepStudyStatuses.map((option) => ({ value: option, label: option })),
        multiple: true,
    },
    {
        available: true,
        value: 'mdStatus',
        label: 'Clinical Status',
        statusOptions: mdStatuses.map((option) => ({ value: option, label: option })),
        multiple: true,
    },
    {
        available: true,
        value: 'cpapOrderStatus',
        label: 'CPAP Order Status',
        statusOptions: cpapOrderStatuses.map((option) => ({ value: option, label: option })),
        multiple: true,
    },
    {
        available: true,
        value: 'supplyOrdersStatus',
        label: 'Supplies Orders Status',
        statusOptions: supplyOrdersStatuses.map((option) => ({ value: option, label: option })),
        multiple: true,
    },
    {
        available: true,
        value: 'appointmentStatus',
        label: 'Appointment Status',
        statusOptions: appointmentStatusOptions,
        multiple: true,
    },
    {
        available: true,
        value: 'appointmentType',
        label: 'Appointment Type',
        statusOptions: appointmentTypeOptions.map((option) => ({ value: option, label: option })),
        multiple: true,
    },
    {
        available: true,
        value: 'lastCompliance',
        label: 'Last Compliance Visit',
        statusOptions: lastComplianceOptions,
        multiple: false,
    },
    {
        available: true,
        value: 'initialCompliance',
        label: '90 Day Compliance',
        statusOptions: initialComplianceOptions.map((option) => ({ value: option, label: option })),
        multiple: true,
    },
];

export const supportOnlyQuestions = [
    {
        answerKey: 'weCanHelp',
        type: null,
        subhead: null,
        title: 'Great!',
        underhead: null,
        modalLabel: null,
        content: 'GEM is here to support you through that process! ',
        skipText: 'Continue',
        skipAsButton: true,
        modalContent: null,
        skip: false,
    },
];

export const apneaQuestions = [
    {
        answerKey: 'previouslyDiagnosedWithSleepApnea',
        type: 'yesno',
        subhead: null,
        title: 'Have you ever been diagnosed with sleep apnea?',
        content: null,
        skip: false,
    },
    {
        answerKey: 'howBroughtToGem',
        type: 'list',
        subhead: null,
        title: 'What brings you to gem sleep?',
        underhead: null,
        content: null,
        multiple: false,
        uniqueChoice: 'NONE',
        choices: [
            {
                value: 'LOOKING_FOR_OTHER_OPTIONS',
                label: 'I am using CPAP, but looking for an alternative treatment',
            },
            {
                value: 'NEED_NEW_MACHINE',
                label: 'I need a new CPAP machine and supplies',
            },
            {
                value: 'NEED_NEW_SLEEP_TEST',
                label: 'Need a new sleep test/prescription',
            },
            // {
            //     value: 'STRUGGLE_WITH_CPAP',
            //     label: 'I need coaching to use my existing CPAP',
            // },
            { value: 'OTHER', label: "My reason isn't listed here" },
        ],
        skip: false,
    },
    {
        answerKey: 'weCanHelp',
        type: null,
        subhead: null,
        title: 'We can help!',
        underhead: 'You are at the right place',
        modalLabel: null,
        content: 'To get started we first need to collect a little bit more information about you!',
        skipText: 'Continue',
        skipAsButton: true,
        modalContent: null,
        skip: false,
    },
    {
        answerKey: 'hasPriorStudy',
        type: 'yesno',
        subhead: null,
        title: 'Do you have a sleep study report you can upload?',
        underhead: '(less than 5 years old) ',
        content: null,
        modalLabel: 'Where can I get a copy of my sleep study?',
        modalContent:
            'If you have access to an online portal through your prior sleep clinic (such as MyChart), you should be able to download a copy. If not, try reaching out to your clinic to have them send you a digital copy.',
        skip: false,
    },
    {
        answerKey: 'needNewSleepStudy',
        type: null,
        subhead: null,
        title: "Let's get you a new sleep study",
        modalLabel: null,
        content:
            "In order to proceed with using GEM SLEEP for treatment we need a valid sleep study. Let's finish setting up your account. In just a few short clicks you can order a new home sleep test.",
        skipText: 'Order a home sleep test',
        skipAsButton: true,
        modalContent: null,
        skip: false,
    },
    {
        answerKey: 'doesSnoreLoudly',
        type: 'yesno',
        subhead: null,
        subheadPreviouslyDiagnosed: 'While NOT using treatment,',
        title: 'Do you snore loudly?',
        titlePreviouslyDiagnosed: 'do you snore loudly?',
        modalLabel: 'How loud is loud?',
        content: null,
        skipText: null,
        modalContent:
            "If you snore louder than most people's indoor speaking voice—about 60 decibels—then sleep doctors consider you a loud snorer. It takes between 40 and 70 decibels to wake someone up, so you might also be a loud snorer if you've ever been a human alarm clock.",
        skip: false,
    },
    {
        answerKey: 'isTired',
        type: 'yesno',
        subhead: null,
        title: 'Are you tired during the day?',
        modalLabel: 'How tired are we talking?',
        content: null,
        skipText: null,
        modalContent:
            "You're prone to feeling sleepy, groggy or fatigued at any time of day, causing you to struggle to stay awake.",
        skip: false,
    },
    {
        answerKey: 'doesStopBreathing',
        type: 'yesno',
        subhead: 'Has anyone observed you:',
        subheadPreviouslyDiagnosed: 'While NOT using treatment, has anyone observed you:',
        title: 'stop breathing, or struggle to breath while sleeping?',
        modalLabel: 'How would I know?',
        content: null,
        skipText: null,
        modalContent:
            "People with sleep apnea may appear to be gasping for air or choking in their sleep, although they often don't notice it on their own. If you're unsure whether that happens to you, please select “No.”",
        skip: false,
    },
    {
        answerKey: 'doesStopBreathing',
        type: 'yesno',
        subhead: null,
        title: 'When sleeping on your back, have you ever woken up gasping or choking?',
        modalLabel: 'How would I know?',
        content: null,
        skipText: null,
        modalContent:
            "People with sleep apnea may appear to be gasping for air or choking their sleep (typically during an apnea event), although they often don't notice it on their own. If you're unsure whether this happens to you, please select “No.”",
        skip: false,
    },
    {
        answerKey: 'isMale',
        type: 'yesno',
        subhead: null,
        yesNoOverride: ['Female', 'Male'],
        title: 'Are you...',
        modalLabel: 'Why do we ask this?',
        content: null,
        skipText: 'I prefer not to say',
        modalContent:
            'Population-based studies show men are two to three times more likely to have sleep apnea than women.',
        skip: false,
    },
    {
        answerKey: 'hasHypertension',
        type: 'yesno',
        subhead: null,
        title: 'Have you been diagnosed with high blood pressure (hypertension)?',
        modalLabel: null,
        content: null,
        skipText: null,
        modalContent: null,
        skip: false,
    },
    {
        answerKey: 'heightInches',
        type: 'height',
        subhead: null,
        title: 'How tall are you?',
        content: null,
        skip: false,
    },
    {
        answerKey: 'weightLbs',
        type: 'weight',
        subhead: null,
        title: 'How much do you weigh?',
        content: null,
        skip: false,
    },
    {
        answerKey: 'dateOfBirth',
        type: 'date',
        subhead: null,
        title: 'What is your date of birth?',
        content: null,
        skip: false,
    },
    {
        answerKey: 'highRiskSelections',
        type: 'checklist',
        subhead: null,
        title: 'Do any of the following Home Sleep Study exclusions apply to you?',
        titlePreviouslyDiagnosed: 'Do any of the following health conditions apply to you?',
        underhead: '(Check all that apply)',
        content: null,
        multiple: true,
        uniqueChoice: 'NONE',
        choices: [
            {
                value: 'HEART_OR_LUNG',
                label: 'Have serious heart or lung disease (examples: heart failure, stroke, COPD, condition requiring a pacemaker)',
            },
            {
                value: 'OPIOID_MEDS',
                label: 'Currently taking Opioid, Alpha Blocker or Short Acting Nitrate medications*',
            },
            { value: 'INSOMNIA', label: 'Severe insomnia' },
            { value: 'NONE', label: 'None of the above' },
        ],
        choicesPreviouslyDiagnosed: [
            {
                value: 'NONCPAP_DEVICE_IN_USE',
                label: 'Currently using a BiPAP, Bilevel PAP, AVAPS, or ASV machine for treatment.',
            },
            {
                value: 'HEART_OR_LUNG',
                label: 'Have serious heart or lung disease (examples: heart failure, stroke, COPD, condition requiring a pacemaker)',
            },
            {
                value: 'OPIOID_MEDS',
                label: 'Currently taking Opioid, Alpha Blocker or Short Acting Nitrate medications*',
            },
            { value: 'INSOMNIA', label: 'Severe insomnia' },
            { value: 'NONE', label: 'None of the above' },
        ],
        modalLabel: '*What are these medications?',
        modalContent:
            'The most common name brand/generic names for these medications are: Opioids (Oxycontin, Percocet), Alpha Blockers (Doxasoine, Prazosin, Terazosin), Short Acting Nitrates (Nitroglycerine). Taking one of these medications may affect the results of a Home Sleep Study.',
        skip: false,
    },
    {
        answerKey: 'highRiskSelections',
        type: 'checklist',
        subhead: null,
        title: 'Do any of the following sleep conditions apply to you?',
        underhead: '(Check all that apply)',
        content: null,
        multiple: true,
        uniqueChoice: 'NONE',
        choices: [
            {
                value: 'RESTLESS_LEGS',
                label: 'Restless Legs Syndrome',
            },
            {
                value: 'SLEEPWALKING',
                label: 'Sleepwalking',
            },
            { value: 'DREAM_ENACTMENT', label: 'Dream Enactment' },
            { value: 'Circadian', label: 'Circadian Rhythm Disorder ' },
            { value: 'REM_DISORDER', label: 'REM Sleep Behavior Disorder' },
            { value: 'Seizures', label: 'Sleep Seizures' },
            { value: 'NONE', label: 'None of the above' },
        ],
        //Skip by default, ask for coaching only users
        skip: true,
    },
    {
        answerKey: 'gained20Lbs',
        type: 'yesno',
        subhead: 'Since your last sleep study, have you:',
        title: 'gained or lost 20+ lbs?',
        content: null,
        skipText: "I don't know",
        //Skip by default, ask for previously diagnosed
        skip: true,
    },
    {
        answerKey: 'situationChanged',
        type: null,
        subhead: null,
        title: "Let's get you set-up with a new Home Sleep Test!",
        modalLabel: null,
        content:
            'Based on the responses to your question, it appears your situation has changed since your last study. We want to make sure we can provide you with the best recommended treatment for your current situation. To do so, you will need to complete a new Home Sleep Test. Luckily, this process is very simple.',
        skipText: 'Get started',
        skipAsButton: true,
        modalContent: null,
        //Skip by default, show only if they have gained or lost 20 pounds
        skip: true,
    },
];

export const truncate = (str, n) => {
    return str.length > n ? str.slice(0, n - 1) + '...' : str;
};

export const slugify = (string) => {
    if (!string) return '';

    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w-]+/g, '') // Remove all non-word characters
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};

export const formatPatientProfile = (patientProfile) => {
    const formattedProfile = {};
    const excludedKeys = [
        'id',
        'dentalPractice',
        'accountCreationStatus',
        'homeSleepStudyStatus',
        'cpapOrderStatus',
        'highRiskSelections',
        'mdStatus',
        'isDentalReferred',
        'dentalReferralStatus',
        'oralApplianceQuestionnaireResultId',
        'hasLowerTeeth',
        'hstLabOrderUserId',
        'npsScore',
        'npsFeedback',
        'stopBangResultId',
        'hstReviewedAt',
        'sovaSageConsultKey',
        'sovaSageConsultId',
        'referFromOrganizationId',
        'referToOrganizationId',
        'treatmentSupportStatus',
        'supplyOrdersStatus',
        'treatments',
        'lastDownloadedPrescriptionFileAt',
        'referToSleepClinic',
        'referredForCpap',
        'needsSleepStudyRetest',
        'supplyOrdersStatus',
        'patientReportedInsurance',
        'referToOrganization',
        'referFromOrganization',
        'questionnaireStatus',
    ];

    Object.keys(patientProfile)
        .filter((k) => excludedKeys.indexOf(k) < 0)
        .forEach((key) => {
            let formattedKey = key;

            switch (key) {
                case 'accountCreationStatus':
                    formattedKey = 'Account Status';
                    break;

                case 'homeSleepStudyStatus':
                    formattedKey = 'Home Sleep Study Status';
                    break;

                case 'cpapOrderStatus':
                    formattedKey = 'CPAP Order Status ';
                    break;

                case 'lastChangedById':
                    formattedKey = 'Last Change By';
                    break;

                case 'hstLabOrderDate':
                    formattedKey = 'HST Lab Ordered On';
                    break;

                case 'createdAt':
                    formattedKey = 'Created';
                    break;

                case 'updatedAt':
                    formattedKey = 'Last Updated On';
                    break;

                default:
                    formattedKey = key;
                    break;
            }

            formattedProfile[formattedKey] =
                key === 'createdAt' || key === 'updatedAt' || key === 'hstLabOrderDate'
                    ? patientProfile[key] !== null
                        ? formatDate(patientProfile[key])
                        : 'N/A'
                    : (patientProfile[key] ?? 'N/A');

            if (key === 'referToOrganization' || key === 'referFromOrganization') {
                formattedProfile[formattedKey] = patientProfile[key]?.name ?? 'N/A';
            }
        });

    return (
        <ul className="grid grid-cols-2 lg:grid-cols-3 gap-4">
            {Object.keys(formattedProfile).map((key, idx) => (
                <li key={`pp-${idx}`} className="overflow-hidden text-ellipsis">
                    <span className="text-gray-light">{key}</span>:{' '}
                    {typeof formattedProfile[key] === 'object'
                        ? JSON.stringify(formattedProfile[key])
                        : formattedProfile[key]}
                </li>
            ))}
        </ul>
    );
};

export const getBlogPostsByCPAPStatus = (CPAPStatus) => {
    switch (CPAPStatus) {
        case 'HST_COMPLETED_HAS_OSA':
            return {
                quote: 'understanding-your-ahi-score',
                resources: ['what-is-a-cpap', 'what-is-an-oral-appliance'],
            };

        case 'SUPPLIES_ORDERED':
        case 'FULFILLMENT_ORDER':
            return {
                quote: 'how-gem-addresses-barrier-to-osa-treatment',
                resources: ['what-is-rem-sleep', 'getting-started-with-cpap'],
            };

        case 'SHIPPED':
            return {
                quote: 'gem-sleeps-cpap-tips-tricks',
                resources: ['what-is-a-cpap', 'getting-started-with-cpap'],
            };

        case 'DELIVERED':
            return {
                quote: 'resmed-airsense11-quick-start-guide',
                resources: ['gem-sleeps-cpap-tips-tricks', 'caring-for-your-cpap'],
            };

        default:
            return {
                quote: 'resmed-airsense11-quick-start-guide',
                resources: ['what-is-a-cpap', 'getting-started-with-cpap'],
            };
    }
};

export const getQuoteIdByCPAPStatus = (CPAPStatus) => {
    switch (CPAPStatus) {
        case 'HST_COMPLETED_HAS_OSA':
            return 'dr-howell';

        case 'SUPPLIES_ORDERED':
            return 'dr-mike-howell';

        case 'FULFILLMENT_ORDER':
            return 'dr-mike-howell';

        case 'SHIPPED':
            return 'dr-mike-howell-2';

        case 'DELIVERED':
            return 'dr-mike-howell-2';

        default:
            return 'dr-mike-howell-2';
    }
};

export const getBlogPostsBySleepStudyStatus = (homeSleepStudyStatus) => {
    switch (homeSleepStudyStatus) {
        case 'NOT_INITIATED':
        case 'STUDY_IN_CART':
        case 'HST_FULFILLMENT_ORDERED':
            return {
                quote: 'what-is-a-sleep-study-and-how-does-it-work',
                resources: ['what-is-sleep-apnea', '6-signs-you-may-have-sleep-apnea'],
            };

        case 'STUDY_ORDERED':
        case 'SERIAL_NUMBER_ADDED':
            return {
                quote: '8-myths-about-sleep-studies-and-cpap-machines',
                resources: [
                    'talking-to-your-partner-about-sleep-apnea',
                    'how-sleep-impacts-your-brain-health',
                ],
            };

        case 'HST_FULFILLMENT_SHIPPED':
        case 'HST_FULFILLMENT_DELIVERED':
            return {
                quote: 'https://www.mygemsleep.com/hst-how-to-guide',
                resources: [
                    'how-much-better-you-could-feel',
                    'the-long-term-impacts-of-sleep-apnea-on-you-and-your-partner',
                ],
            };

        case 'STUDY_COMPLETED':
            return {
                quote: '8-myths-about-sleep-studies-and-cpap-machines',
                resources: [
                    'how-much-better-you-could-feel',
                    'how-gem-addresses-barrier-to-osa-treatment',
                ],
            };

        case 'STUDY_REVIEWED':
            return {
                quote: 'understanding-your-ahi-score',
                resources: ['how-much-better-you-could-feel', 'what-is-an-oral-appliance'],
            };

        default:
            return {
                quote: 'dr-howell',
                resources: [
                    'how-much-better-you-could-feel',
                    'how-gem-addresses-barrier-to-osa-treatment',
                ],
            };
    }
};

export const getQuoteIdBySleepStudyStatus = (homeSleepStudyStatus) => {
    switch (homeSleepStudyStatus) {
        case 'NOT_INITIATED':
        case 'STUDY_IN_CART':
        case 'HST_FULFILLMENT_ORDERED':
            return 'just-one-night-of-sleep';

        case 'STUDY_ORDERED':
        case 'SERIAL_NUMBER_ADDED':
            return 'quote-on-diagnosis';

        case 'HST_FULFILLMENT_SHIPPED':
        case 'HST_FULFILLMENT_DELIVERED':
            return 'follow-these-simple-steps';

        case 'STUDY_COMPLETED':
            return 'sleep-apnea-is-common';

        case 'STUDY_REVIEWED':
        default:
            return 'dr-howell';
    }
};

export const otherThingsItems = [
    {
        title: 'What is a home sleep study test?',
        content: (
            <>
                <p>
                    Advances in technology have created the possibility for individuals to capture
                    their sleep pattern data and transfer it to a doctor from the comfort of their
                    very own home… in their very own bed. No clinics, no wires, and no one watching
                    you sleep (except maybe your dog).
                </p>
                <p>
                    GEM utilizes a tool called WatchPAT&trade;, a small wearable device (similar to
                    a Fitbit or Apple Watch) that gathers the same information as a traditional
                    sleep study, but in a MUCH more comfortable way. We will mail the device to your
                    home, have you watch a short online tutorial about how to link it to your
                    smartphone, and BOOM, you're a sleep apnea expert. Well, ok, not an EXPERT, but
                    you're certainly ready to get evaluated by one.
                </p>
            </>
        ),
    },
    {
        title: 'Does GEM SLEEP take insurance?',
        content: (
            <>
                <p>
                    GEM SLEEP is actively working to accept insurance directly. Given the Home Sleep
                    Test and CPAP Machines/Supplies are for healthcare you may be able to:
                </p>
                <ul className="ml-4 list-disc">
                    <li>Use your HSA or FSA account to make the purchase</li>
                    <li>
                        Submit your receipts to your insurance company to count towards your
                        out-of-pocket deductible
                    </li>
                </ul>
                <p>
                    Join the{' '}
                    <a
                        href="https://mygemsleep.com/waitlist"
                        className="inline-block border-b-2 border-green-400">
                        GEM SLEEP waitlist
                    </a>{' '}
                    to be on our priority list for updates and exciting product offerings.{' '}
                </p>
            </>
        ),
    },
];

export const otherThingsDentalPracticeItems = [
    {
        title: 'What is a home sleep study test?',
        content: (
            <>
                <p>
                    Advances in technology have created the possibility for individuals to capture
                    their sleep pattern data and transfer it to a doctor from the comfort of their
                    very own home… in their very own bed. No clinics, no wires, and no one watching
                    you sleep (except maybe your dog).
                </p>
                <p>
                    GEM utilizes a tool called WatchPAT&trade;, a small wearable device (similar to
                    a Fitbit or Apple Watch) that gathers the same information as a traditional
                    sleep study, but in a MUCH more comfortable way. We will mail the device to your
                    home, have you watch a short online tutorial about how to link it to your
                    smartphone, and BOOM, you're a sleep apnea expert. Well, ok, not an EXPERT, but
                    you're certainly ready to get evaluated by one.
                </p>
            </>
        ),
    },
    {
        title: 'Does GEM SLEEP take insurance?',
        content: (
            <>
                <p>
                    GEM SLEEP is actively working to accept insurance directly. Given the Home Sleep
                    Test and CPAP Machines/Supplies are for healthcare you may be able to:
                </p>
                <ul className="ml-4 list-disc">
                    <li>Use your HSA or FSA account to make the purchase</li>
                    <li>
                        Submit your receipts to your insurance company to count towards your
                        out-of-pocket deductible
                    </li>
                </ul>
                <p>
                    Join the{' '}
                    <a
                        href="https://mygemsleep.com/waitlist"
                        className="inline-block border-b-2 border-green-400">
                        GEM SLEEP waitlist
                    </a>{' '}
                    to be on our priority list for updates and exciting product offerings.{' '}
                </p>
            </>
        ),
    },
    {
        title: 'Will my dentist be notified when my results are complete?',
        content: (
            <p>
                Your dentist will have access to your test results via GEM, but you will be schedule
                your next visit with them via the GEM Portal at a time that is convenient for you.
            </p>
        ),
    },
];

export const stepsIfRight = [
    'Understand where you’re at – Take our short assessment to see if GEM SLEEP is right for you. Then create a free account to unlock access to sleep apnea resources.',
    'Test your sleep – Complete a sleep apnea test at home. Receive a clinical diagnosis from a GEM SLEEP provider in just days.',
    'Start your treatment – Select a treatment customized for you. We’ll be here to support you as you get started and every step of the way.',
];

export const stepsIfRightDentalPractice = (dentalPracticeName) => [
    'Click the button above to indicate where you are at in your sleep journey.',
    'Tell us a little bit about yourself!',
    `GEM SLEEP will guide you to next steps in your journey! `,
];

/**
 * A linear interpolator for hexadecimal colors
 * @param {String} a
 * @param {String} b
 * @param {Number} amount
 * @example
 * // returns #7F7F7F
 * lerpColor('#000000', '#ffffff', 0.5)
 * @returns {String}
 */
export function lerpColor(a, b, amount) {
    let ah = +a.replace('#', '0x'),
        ar = ah >> 16,
        ag = (ah >> 8) & 0xff,
        ab = ah & 0xff,
        bh = +b.replace('#', '0x'),
        br = bh >> 16,
        bg = (bh >> 8) & 0xff,
        bb = bh & 0xff,
        rr = ar + amount * (br - ar),
        rg = ag + amount * (bg - ag),
        rb = ab + amount * (bb - ab);

    return '#' + (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1);
}

export const reactSelectCustomStyles = (theme = 'dark') => ({
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        paddingBottom: '1rem',
        color: theme === 'light' ? 'inherit' : '#fff',
        fontWeight: 600,
        fontSize: 'inherit',
        ':hover': {
            cursor: 'pointer',
        },
    }),
    value: (provided) => ({
        ...provided,
        color: theme === 'light' ? 'inherit' : '#fff',
    }),
    input: (provided) => ({
        ...provided,
        color: theme === 'light' ? 'inherit' : '#fff',
        margin: 0,
        padding: 0,
    }),
    singleValue: (provided) => ({
        ...provided,
        color: theme === 'light' ? 'inherit' : '#fff',
    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent',
        borderRadius: 0,
        border: 0,
        height: '100%',
        borderBottom:
            theme === 'light'
                ? `1px solid ${state.hasValue ? 'rgba(43, 47, 58, 1)' : 'rgba(43, 47, 58, .4)'}`
                : `1px solid ${state.hasValue ? '#fff' : 'rgba(255,255,255,0.25)'}`,
        padding: 0,
        outline: 'none',
        boxShadow: 'none',
        ':hover': {
            borderColor: theme === 'light' ? 'rgba(43, 47, 58, 1)' : '#fff',
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: theme === 'light' ? 'rgba(43, 47, 58, 1)' : '#f0f2f5',
        opacity: 0.7,
        fontWeight: 400,
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#fff',
        borderRadius: 0,
        ':hover': {
            cursor: 'pointer',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? '#fff' : '#000',
        fontSize: '14px',
        backgroundColor: state.isFocused ? '#19a756' : 'transparent',
        ':hover': {
            backgroundColor: '#19a756',
            color: '#fff',
            cursor: 'pointer',
        },
    }),
});

export const adminSelectCustomStyles = {
    singleValue: (provided) => ({
        ...provided,
        color: '#fff',
    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent',
        height: '100%',
        padding: 2,
        outline: 'none',
        boxShadow: 'none',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#f0f2f5',
        opacity: 0.7,
        fontWeight: 400,
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#fff',
        borderRadius: 4,
        ':hover': {
            cursor: 'pointer',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? '#fff' : state.data.color ? state.data.color : '#000',
        fontSize: '14px',
        backgroundColor: state.isFocused ? '#19a756' : 'transparent',
        ':hover': {
            backgroundColor: '#19a756',
            color: '#fff',
            cursor: 'pointer',
        },
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: 'black',
        ':hover': {
            backgroundColor: 'red',
            color: 'white',
        },
    }),
};

export const modalSelectCustomStyles = {
    value: (provided) => ({
        ...provided,
        color: 'black',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent',
        height: '100%',
        padding: 2,
        outline: 'none',
        boxShadow: 'none',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'black',
        opacity: 0.7,
        fontWeight: 400,
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#fff',
        borderRadius: 4,
        ':hover': {
            cursor: 'pointer',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? '#fff' : state.data.color ? state.data.color : '#000',
        fontSize: '14px',
        backgroundColor: state.isFocused ? '#19a756' : 'transparent',
        ':hover': {
            backgroundColor: '#19a756',
            color: '#fff',
            cursor: 'pointer',
        },
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: 'black',
        ':hover': {
            backgroundColor: 'red',
            color: 'white',
        },
    }),
};

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }
        return this.props.children;
    }
}

export const productOptionPanelVariants = {
    expanded: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
};

export const overlayVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
};

export const parentPageVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
};

export const formatOptionValue = (value) => {
    switch (value) {
        case 'Small':
            return 'S';
        case 'Medium':
            return 'M';
        case 'Large':
            return 'L';
        case 'Wide':
            return 'Wide';
        case 'Small-Wide':
            return 'Small Wide';
        case 'Small and Medium':
            return 'Small Medium';

        default:
            return value;
    }
};

export const objectsEqual = (o1, o2) =>
    typeof o1 === 'object' && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length &&
          Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
        : o1 === o2;

export const arraysEqual = (a1, a2) =>
    a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

/**
 *
 * @param {int} price In cents... Because reasons?
 * @returns
 */
export const formatSquarePrice = (price) => {
    if (!price || price === 0) return '0.00';
    return (price / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// prices are in cents, but when we convert back we need to handle
// if they have cents after decimal, if not we need to add .00
// also parseInt does not like commas so they need to stripped.
export const stringPriceToNumber = (price) => {
    if (!price.includes('.')) {
        return parseInt(price.replace(/,/g, ''), 10) * 100;
    }
    return parseInt(price.replace(/,/g, '').replace('.', ''), 10);
};
/**
 *
 * @param {int} weight Weight in pounds
 * @param {int} height Height in inches
 * @returns
 */
export const getBMI = (weight, height) => {
    return !weight || !height ? null : ((703 * weight) / Math.pow(height, 2)).toFixed(1);
};

/**
 *
 * @param {string} dateString Date in ISO format
 * @returns
 */
export const getAgeFromDateString = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;

    return age;
};

export const getVariationDataFromId = (variantId, catalogItems) => {
    let data = { product: null, variation: null };

    catalogItems.forEach((p) => {
        const v = p.itemData.variations.find((v) => v.id === variantId);

        if (v) {
            data.variation = v;
            data.product = p;
        }
    });

    return data;
};

export function getFormattedVariationOptionsBySku(sku, catalogItems, catalogOptions) {
    if (!catalogItems || !sku) return [];

    const targetVariation = catalogItems
        .find((item) => item.itemData?.variations?.some((v) => v.itemVariationData.sku === sku))
        ?.itemData.variations.find((v) => v.itemVariationData.sku === sku);

    if (!targetVariation || !targetVariation.itemVariationData.itemOptionValues) return [];

    const formattedOptions = targetVariation.itemVariationData.itemOptionValues.map((opt) => {
        const optData = catalogOptions.find((item) => opt.itemOptionId === item.id)?.itemOptionData;

        if (!optData) return [];

        const optValue = optData.values.find((d) => d.id === opt.itemOptionValueId)
            ?.itemOptionValueData.name;

        return {
            name: optData.name,
            value: optValue,
        };
    });

    return formattedOptions;
}

export const formatSizeStrings = (str) =>
    typeof str === 'string'
        ? str.replace('Small', 'S')?.replace('Large', 'L')?.replace('Medium', 'M')
        : '';

export const toDate = (date) => {
    return parse(date, 'yyyy-MM-dd HH:mm:ss xx', new Date());
};

export const convertEnumValueToDisplayString = (enumValue) => {
    // capitalize first char and lower case characters of each word, convert _ to space
    return enumValue
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
};

export const getAppointmentObject = (obj) => {
    if (!obj) return null;

    let appointment = JSON.parse(JSON.stringify(obj));

    if (obj.appointmentStatus !== 'SCHEDULED') return null;

    appointment.name = obj.appointmentType;
    appointment.start = format(new Date(obj.startAt), 'EEEE M/d h:mm a');
    appointment.timeframe = `${format(new Date(obj.startAt), 'h:mm')}-${format(
        new Date(obj.endAt),
        'h:mm a (z)',
    )}`;

    return appointment;
};

//Matching SKUs to the Healthie Names
export const appointmentSkus = new Map([
    ['DIACLINICAL', 'Diagnosis Review'],
    ['COMPLIANCE', 'Compliance Visit'],
    ['REQCONSULT', 'Initial Consultation'],
]);

//Color the statuses to easily spot inactive or in review, cancellations, noshows
export function colorInsuranceStatus(status) {
    if (status === 'Inactive') return <span className="text-red">Inactive</span>;
    else if (status === 'Active') return <span className="text-gem-green">Active</span>;
    else return <span className="text-yellow">{status}</span>;
}

export function colorByDateUpdated(date) {
    const daysSince = differenceInDays(new Date(), date);
    const color = daysSince < 3 ? 'text-gem-green' : daysSince < 5 ? 'text-yellow' : 'text-red';
    return color;
}

export const MAX_DAYS_OUT_SCHEDULING = 60;

// Used to determine the orderBy object for Orders
export const orderByObjectKeys = new Map([
    ['ID', { id: 'asc' }],
    ['Updated', { updatedAt: 'asc' }],
    ['Date', { orderDate: 'asc' }],
]);

export function determineOrderCategories(order) {
    let orderItemCategories =
        order && order.orderItems ? order.orderItems.map((item) => item.itemCategory) : [];

    const hasDeposit = orderItemCategories.includes('DEPOSIT');
    const hasOtherItems = orderItemCategories.includes('OTHER');
    const isCpapOrder = orderItemCategories.includes('CPAP_SUPPLIES');
    const isHSTOrder = orderItemCategories.includes('SLEEP_STUDY');
    const isConsultOrder = orderItemCategories.includes('CLINICAL_CONSULTS');
    const isSuppliesOrder = orderItemCategories.includes('SUPPLIES');

    return {
        orderItemCategories,
        hasDeposit,
        hasOtherItems,
        isCpapOrder,
        isHSTOrder,
        isConsultOrder,
        isSuppliesOrder,
    };
}

export function determineItemCategoryTags(order) {
    const { hasDeposit, hasOtherItems, isCpapOrder, isHSTOrder, isConsultOrder, isSuppliesOrder } =
        determineOrderCategories(order);

    let tags = [];

    if (isConsultOrder) tags.push(<span>Consult</span>);
    if (isHSTOrder) tags.push(<span>HST</span>);
    if (isCpapOrder) tags.push(<span>CPAP</span>);
    if (isSuppliesOrder) tags.push(<span>SUPPLIES</span>);
    if (hasDeposit) tags.push(<span>DEPOSIT</span>);
    if (hasOtherItems) tags.push(<span>OTHER</span>);
    if (tags.length === 0) tags.push(<span className="text-gray">Empty</span>);

    return tags.map((p, idx) => (
        <>
            {idx > 0 && <span key={idx}>, </span>}
            {p}
        </>
    ));
}

export function colorAppointmentStatus(status) {
    if (status === 'SCHEDULED') return <span className="text-gem-green">SCHEDULED</span>;
    else if (status === 'CANCELLED' || status === 'NOSHOW')
        return <span className="text-red">{status}</span>;
    else return <span className="text-gray">{status}</span>;
}

export function handleNullOptions(whereObject) {
    const key = Object.keys(whereObject)[0];

    if (!whereObject[key]?.in?.some((option) => option === null) && whereObject[key] !== 'null') {
        return whereObject;
    } else {
        let nonNullValues = {};
        nonNullValues[key] = {
            in: whereObject[key].in?.filter((option) => option !== null),
        };

        let nullOption = {};
        nullOption[key] = null;

        const formattedObject = {
            OR: [nonNullValues, nullOption],
        };

        return formattedObject;
    }
}

//Swap elements in an array
export function swapElements(arr, x, y) {
    if (arr.length > 1) {
        [arr[x], arr[y]] = [arr[y], arr[x]];
    }

    return arr;
}

export const orderItemCategories = [
    { value: 'CPAP_SUPPLIES', label: 'CPAP' },
    { value: 'CLINICAL_CONSULTS', label: 'Clinical Consults' },
    { value: 'SLEEP_STUDY', label: 'Sleep Study' },
    { value: 'SUPPLIES', label: 'Supplies' },
    { value: 'OTHER', label: 'Other' },
];

export function determineMaskBySku(sku, catalogItems) {
    if (!sku) {
        return { maskName: 'None', maskSize: '' };
    }

    const itemBySku = catalogItems?.find((o) =>
        o?.itemData?.variations?.find(
            (v) => v.itemVariationData?.sku.toLowerCase() === sku.toLowerCase(),
        ),
    );

    if (!itemBySku) {
        return { maskName: 'Not in Catalog', maskSize: '' };
    }

    const size = itemBySku.itemData.variations.find(
        (variation) => variation.itemVariationData.sku === sku,
    ).itemVariationData.name;

    return { maskName: itemBySku.itemData.name, maskSize: size };
}
